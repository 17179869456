import React from "react";
import { graphql } from "gatsby";
import ParallaxHeader from "../components/ParallaxHeader";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { BsTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import Markdown from "markdown-to-jsx";
import { Helmet } from "react-helmet";
import { formatPhoneNumber } from "../util/format";
export const CareersTemplate = ({ title, image, jobs, intro, benefits }) => {
  const headerImage = getImage(image) || image;
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <ParallaxHeader className="h-60" image={headerImage} factor={0.7}>
        <motion.h1
          initial={{ x: -10, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "ease",
            },
          }}
          className="z-10 text-white text-5xl "
        >
          {title}
        </motion.h1>
      </ParallaxHeader>
      <section className="flex flex-col items-center justify-center bg-white">
        <div className="container flex flex-col lg:flex-row py-10 px-6">
          <div className="md-content lg:mr-10">
            <h1 className="text-brown text-4xl mb-3">{intro.header}</h1>
            <Markdown className="md-content md-theme-color">
              {intro.content}
            </Markdown>
            <div className="text-navy mb-6">
              <BsTelephoneFill className="inline text-navy mr-2 mb-1 text-lg"></BsTelephoneFill>
              Contact us to learn more:{" "}
              <a
                className="underline hover:text-navyHover"
                href={`tel:${intro.phone}`}
              >
                {formatPhoneNumber(intro.phone)}
              </a>
            </div>
            <div className="text-navy mb-6">
              <GrMail className="inline text-navy mr-2 mb-1 text-lg" />
              Or send your resume to:{" "}
              <a
                className="underline hover:text-navyHover"
                href={`mailto:${intro.email}`}
              >
                {intro.email}
              </a>
            </div>
          </div>
          <div className="w-full lg:w-[500px] bg-navy p-8 shadow rounded-sm text-white">
            <Markdown className="text-white md-content">{benefits}</Markdown>
          </div>
        </div>
      </section>
      <section className="bg-beige-300/[0.3] flex flex-col items-center w-full justify-center">
        <div className="container p-6">
          <h1 className="text-darkbrown text-3xl mb-6 ">Open Positions:</h1>
          {jobs.map((job, idx) => (
            <ListingCard job={job} key={idx} />
          ))}
        </div>
      </section>
    </div>
  );
};

function ListingCard({ job }) {
  return (
    <div className="shadow-lg bg-white rounded-sm p-8 my-4 relative" >
      <div className="absolute top-[-70px]" id={job.id || `${Math.random(0, 10)}`}></div>
      <h1 className="text-navy text-2xl mb-2">{job.title}</h1>
      {job.location && (
        <h3>
          <span className="text-navy inline mr-2">
            <ImLocation className="inline" />
          </span>
          {job.location}
        </h3>
      )}
      <hr className="w-full border-b-2 my-5 border-navy pr-8"></hr>

      <p className="text-darkbrown/[0.8] ">Details:</p>
      <div>
        {job.description && (
          <Markdown className="md-content">{job.description}</Markdown>
        )}
      </div>
      <div className="flex flex-row justify-end w-full">
        <a
          className="text-navy hover:text-navyHover"
          href="mailto:careers@bcservices-inc.com"
        >
          Apply Now
        </a>
      </div>
    </div>
  );
}
export default function CareersPage({ data }) {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <Helmet>
        <title>Join Our Team - Brown Construction Services</title>
      </Helmet>
      <CareersTemplate
        title={frontmatter.title}
        image={frontmatter.image}
        jobs={frontmatter.jobs}
        intro={frontmatter.intro}
        benefits={frontmatter.benefits}
      />
    </>
  );
}

export const careersPageQuery = graphql`
  query CareersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        intro {
          header
          content
          phone
          email
        }
        benefits
        jobs {
          id
          title
          location
          description
        }
      }
    }
  }
`;
